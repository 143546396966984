import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { faArrowUpFromBracket } from '@fortawesome/pro-solid-svg-icons';
import { ACL_VIEW_ROLES } from '@remento/types/acl';
import { PollStatus } from '@remento/types/poll';

import { PageLoader } from '@/components/PageLoader/PageLoader';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMDialog } from '@/components/RMDialog';
import { toast } from '@/components/RMToast/RMToast';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { PollExpiredPageContainer } from '@/modules/error/containers/PollExpiredPage.container';
import { PollVoting } from '@/modules/poll/components/PollVoting';
import { PollVotingSelection } from '@/modules/poll/components/VotingPollSelection/PollVotingSelection';
import { MembersAvatarListContainer } from '@/modules/poll/containers/MembersAvatarList.container';
import { PollVoteAvatarListContainer } from '@/modules/poll/containers/PollVoteAvatarList.container';
import { PollVotingOption, usePollVotingOptions } from '@/modules/poll/hooks/poll-options.hook';
import { JoinProjectDialog } from '@/modules/project/components/JoinProjectDialog/JoinProjectDialog';
import { getProjectPromptsPath, getSigninPath, RementoPage } from '@/modules/routing';
import { PollShareDialogContainer } from '@/modules/sharing/containers/PollShareDialog.container';
import { useServices } from '@/Services';
import { hasRole, useCurrentUserAclRoles } from '@/services/api/acl';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { usePollQuery } from '@/services/api/poll';
import { useProjectQuery } from '@/services/api/project';
import { captureException } from '@/utils/captureException';
import { openShareSheet, showShareSheetToast } from '@/utils/share-sheet';
import { secureUuid } from '@/utils/uuid';

interface PollVotingLoadingState {
  type: 'loading';
}

interface PollVotingVoteState {
  type: 'vote';
  selectedPollOptionId: string | null;
}

interface PollVotingResultsState {
  type: 'results';
}

type PollVotingState = PollVotingLoadingState | PollVotingVoteState | PollVotingResultsState;

interface InternalPollPageProps {
  pollId: string;
}

function InternalPollPage({ pollId }: InternalPollPageProps) {
  const { pollService, pollCacheService, pollAnalyticsService, redirectService } = useServices();
  const navigate = useNavigate();
  const isMobile = useIsMobileViewport();

  const [searchParams] = useSearchParams();
  const inviteToken = searchParams.get('inviteToken') ?? null;

  // State
  const [state, setState] = useState<PollVotingState>({ type: 'loading' });
  const [joinDialogOpen, setJoinDialogOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [autoActionOnFinish, setAutoActionOnFinish] = useState<boolean>(false);

  // Entities
  const user = useUser();
  const pollQuery = usePollQuery(pollId);
  const projectQuery = useProjectQuery(pollQuery.data?.projectId);
  const project = projectQuery.data;
  const personQuery = usePersonQuery(project?.notifications.recipientPersonIds[0]);
  const userProjectRoles = useCurrentUserAclRoles(projectQuery.data?.acl ?? null);
  const canViewProject = hasRole(ACL_VIEW_ROLES, userProjectRoles ?? []);

  // Wait for the usePollQuery to finish fetching data to run this hook.
  // This will avoid performing two requests at the same time.
  const pollVotingOptions = usePollVotingOptions(pollQuery.data?.id ?? null);

  const handleShare = useCallback(async () => {
    if (pollId == null) {
      return;
    }

    setAutoActionOnFinish(false);
    if (isMobile) {
      const urlPromise = pollService.getPollShareLink(pollId).then((s) => s.link);
      showShareSheetToast(await openShareSheet({ url: urlPromise }));
    } else {
      setShareDialogOpen(true);
    }
  }, [isMobile, pollId, pollService]);

  const handleGoToProjects = useCallback(() => {
    if (pollQuery.data == null) {
      return;
    }

    setAutoActionOnFinish(false);
    if (user == null) {
      setJoinDialogOpen(true);
    } else if (canViewProject) {
      navigate(getProjectPromptsPath(pollQuery.data.projectId));
    } else {
      navigate(`/invite?inviteToken=${inviteToken}`);
    }
  }, [navigate, pollQuery.data, user, canViewProject, inviteToken]);

  const handleSignIn = useCallback(async () => {
    if (pollQuery.data == null) {
      return;
    }

    await redirectService.registerRedirect('signed-in', `/invite?inviteToken=${inviteToken}&from-signin=true`);
    navigate(getSigninPath({ backupLocalData: true }));
  }, [inviteToken, navigate, pollQuery.data, redirectService]);

  const handleVote = useCallback(async () => {
    if (state.type != 'vote' || state.selectedPollOptionId == null) {
      return;
    }

    try {
      await pollCacheService.createPollVote({
        id: secureUuid(),
        pollId,
        promptId: state.selectedPollOptionId,
      });
      pollAnalyticsService.onPollVoted();

      setState({ type: 'results' });
      setAutoActionOnFinish(true);
    } catch (error) {
      captureException(error, true);
      toast('Failed to vote', 'root-toast', 'error');
    }
  }, [pollAnalyticsService, pollCacheService, pollId, state]);

  const handleSelectPollOption = useCallback((option: PollVotingOption) => {
    setState({ type: 'vote', selectedPollOptionId: option.id });
  }, []);

  // Set dialog initial state based on user vote state
  useEffect(() => {
    // Votes are returned with the poll query, so there's no problem waiting for it here
    if (pollQuery.data == null) return;
    pollCacheService.hasVoted(pollQuery.data.id).then(async (hasVoted) => {
      if (hasVoted) {
        setState({ type: 'results' });
      } else {
        setState({ type: 'vote', selectedPollOptionId: null });
      }
    });
  }, [pollCacheService, pollQuery.data]);

  // Auto action after voted
  useEffect(() => {
    if (pollQuery.data == null || state.type !== 'results' || autoActionOnFinish === false) {
      return;
    }

    const timeoutId = setTimeout(() => {
      handleGoToProjects();
    }, 5000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [autoActionOnFinish, handleGoToProjects, pollQuery.data, state.type]);

  if (
    pollQuery.data?.status == PollStatus.FINISHED ||
    pollQuery.data?.status == PollStatus.DELETED ||
    pollQuery.data === null
  ) {
    return <PollExpiredPageContainer pollId={pollId} finished={pollQuery.data.status == PollStatus.FINISHED} />;
  }

  if (pollQuery.data == null) {
    return <PageLoader />;
  }

  return (
    <>
      <PollVoting.Root>
        <PollVoting.Header expireOn={pollQuery.data.expireOn}></PollVoting.Header>
        <PollVoting.Content>
          {state.type === 'loading' && <RMDialog.Loader />}
          {(state.type === 'vote' || state.type === 'results') && (
            <PollVotingSelection
              state={state.type}
              subjectName={personQuery.data?.name ?? null}
              options={pollVotingOptions ?? []}
              selectedOptionId={state.type == 'vote' ? state.selectedPollOptionId : null}
              onSelect={handleSelectPollOption}
              PollVotesList={<PollVoteAvatarListContainer pollId={pollId} />}
            />
          )}
        </PollVoting.Content>

        <PollVoting.Footer>
          {state.type === 'vote' && (
            <RMButton
              background="primary"
              fullWidth
              disabled={state.selectedPollOptionId == null}
              autoLoading
              onClick={handleVote}
            >
              Submit vote
            </RMButton>
          )}

          {state.type === 'results' && (
            <>
              {user && canViewProject && (
                <RMButton leftIcon={faArrowUpFromBracket} background="neutral" fullWidth onClick={handleShare}>
                  Share poll
                </RMButton>
              )}

              <RMButton background="primary" fullWidth onClick={handleGoToProjects}>
                Go to projects
              </RMButton>
            </>
          )}
        </PollVoting.Footer>
      </PollVoting.Root>
      <JoinProjectDialog
        open={joinDialogOpen}
        subjectName={personQuery.data?.name?.first ?? ''}
        MembersList={<MembersAvatarListContainer projectId={pollQuery.data.projectId} />}
        onSignIn={handleSignIn}
      />
      <PollShareDialogContainer
        open={shareDialogOpen}
        projectId={pollQuery.data.projectId}
        pollId={pollId}
        onClose={() => setShareDialogOpen(false)}
      />
    </>
  );
}

export function PollPage() {
  const params = useParams();

  return (
    <RementoPage type="default">
      <InternalPollPage pollId={params.pollId ?? ''} />
    </RementoPage>
  );
}
